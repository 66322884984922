const routes = [
  {
    path: "/",
    component: "Home",
    exact: true
  },
  {
    path: "/account",
    component: "Account",
    exact: true
  },
  {
    path: "/register",
    component: "Register/index", // Rota para a página de registro
    exact: true
  }
];

export default routes;
