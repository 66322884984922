import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Styles />
      
      <Switch>
        {routes.map((routeItem) => {
          const Component = lazy(() => import(`../pages/${routeItem.component}`));
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              exact={routeItem.exact}
              component={Component}
            />
          );
        })}
      </Switch>

    </Suspense>
  );
};

export default Router;
