import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationTl from "./locales/tl/translation.json"; // Certifique-se de que o caminho está correto

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "en", // Idioma padrão
    fallbackLng: "en", // Idioma de fallback

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      tl: {
        translations: translationTl,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
